import $ from 'jquery';
import AES from 'crypto-js/aes';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
export default function(cb) {
  window.m(function(text) {
    var moo = JSON.parse(Utf8.stringify(Base64.parse($('[data-moo]').data().moo)));
    var key = Base64.parse(moo.key);
    var iv = Base64.parse(moo.iv);
    var encrypted = {};
    encrypted.ciphertext = Base64.parse(text);
    var decrypted = JSON.parse(Utf8.stringify(AES.decrypt(encrypted, key, { iv: iv })));
    cb(decrypted);
  })
};
