var CowLogic = {
  setup: function() {
    var closeCowModal = function() {
      $('#cowModal').removeClass('is-visible');
      $('html').removeClass('menu-open');
      $('.overlay').removeClass('is-visible');
      $('.hide-me').removeClass('is-opacity-zero');
      $('.hide-me').removeClass('is-visibility-hidden');
    };

    var showCowDetails = function(uuid) {
      $.get('/cows/' + uuid, function(resp) {
        $('#cowDetailsModal').html(resp).foundation().trigger('open');
      });
    };

    var openCowPalace = function(e) {
      e.preventDefault();
      var opts = {};
      if($(this).data().newCow) { opts.new_cow = true; }
      $.get('/cows/palace', opts, function(resp) {
        closeCowModal();
        $('#cowPalace').html(resp).foundation().trigger('open');
        $('#cowPalace').find('[data-cow-uuid]').on('click', function() {
          var uuid = $(this).data().cowUuid;
          showCowDetails(uuid);
        });
      })
    };

    $('[data-open-cow-palace]').on('click', openCowPalace);
    $('[data-close-cow-modal]').on('click', closeCowModal);

    var showCow = function(cow) {
      cow = JSON.parse(cow)
      $('#unlock-cow').attr('src', cow.cow.unlock_image_url);
      $('#cow-modal-title').html(cow.cow.unlock_message);
      if(cow.new_cow) {
        $('#cowModal [data-open-cow-palace]').data({newCow: true});
      } else {
        $('#cowModal [data-open-cow-palace]').data({newCow: false});
      }
      $('html').toggleClass('menu-open');
      $('.overlay').toggleClass('is-visible');
      $('#cowModal').addClass('is-visible')
    };

    if(typeof(window) !== 'undefined') {
      window.COW = {
        unlock: function(key, obj, cb) {
          obj = obj || {}
          console.log('in unlock');
          $.post('/api/cows/unlock', {key: obj[key] || key}, function(resp) {
            if(resp.error) {
              alert(resp.error);
            } else {
              showCow(resp.cow);
              if(cb) {cb(resp)};
            }
          })
        }
      }
    }
  }
}

export default CowLogic;
