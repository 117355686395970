export default function(cowKeys) {
  var lastUpdate = new Date();
  $(document).on('mousemove', function() {
    lastUpdate = new Date();
  });
  $(document).on('click', function() {
    lastUpdate = new Date();
  })
  var watching = null;
  watching = setInterval(function() {
    var now = new Date;
    if(now - lastUpdate > 5* 60*1000) {
      $('.cow-dozer').addClass('is-visible').off('click').on('click', function() {
        window.COW.unlock("97d7c626-ce42-4962-92d0-c1b759fcbba9", cowKeys);
        clearInterval(watching);
        $('.cow-dozer').removeClass('is-visible');
      });
    }
  }, 1000);
};
