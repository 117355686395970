// styles
import "./scss/app";

// js
import particleJS from "particles.js";
import "./import-images";
import "./import-fonts";
import "what-input";
import cowDozer from "./js/cow-dozer";
import konami from "./js/konami";
import scrollToShow from "./scroll-to-show";
import setupNav from "./js/nav";
import FontFaceObserver from "./js/fontfaceobserver";

import CowLogic from "./js/cow";
import d from "./decrypt";
import setupSpaceCow from "./js/space-cow";
import updateAnalytics from "./js/update-analytics";

$(document).on("turbolinks:load", function(e) {
  $(this).foundation();
  $("html").removeClass("menu-open");

  updateAnalytics(e);

  // CSRF
  $.ajaxSetup({
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content")
    }
  });

  $(".orbit-slide").click(function() {
    $("#carouselContainer").foundation("changeSlide", true);
  });

  var pdpRunOnce = false;
  if ($("#pdpScrollContainer").length > 0) {
    $(document).scroll(function() {
      var pdpScrollContainer = document.getElementById("pdpScrollContainer");
      var offsetTop = $(pdpScrollContainer).offset().top;
      var offsetGap = 500;

      if (window.scrollY > offsetTop - offsetGap && !pdpRunOnce) {
        $(pdpScrollContainer).addClass("scroll-to-bottom");
        pdpRunOnce = true;
      }
    });
  }

  if ($("#particles-js").is("*")) {
    // Hack to get particles to render **usually** better.
    // Wait 1s to initialize the particles then it will fade in with a 2s fade
    setTimeout("(particlesJS.load('particles-js', '/particles.json'))", 1000);
  }

  var pnLight = new FontFaceObserver("Proxima Nova Light");
  var pnReg = new FontFaceObserver("Proxima Nova Regular");
  var pnSem = new FontFaceObserver("Proxima Nova Regular");
  var pnBold = new FontFaceObserver("Proxima Nova Bold");
  var pnExBold = new FontFaceObserver("Proxima Nova Extra Bold");
  var heroes = new FontFaceObserver("Heroes");

  Promise.all([
    pnLight.load(),
    pnReg.load(),
    pnSem.load(),
    pnBold.load(),
    pnExBold.load(),
    heroes.load()
  ]).then(function() {
    $("#particles-js").addClass("is-loaded");
    document.documentElement.className += " fonts-loaded";
  });

  setTimeout(function() {
    document.documentElement.className += " fonts-loaded";
  }, 3000);

  // navigation
  setupNav();

  // subscriber form (blog)
  $("form#subscriberForm").submit(function(e) {
    e.preventDefault();
    var $form = $(this);

    $.post("/api/subscribers", $form.serialize())
      .done(function(lead) {
        $form.html("<h3>Thanks! We'll be in touch soon!</h3>");
      })
      .fail(function(error) {
        console.log("error!", error);
      });
  });

  // wired submission
  $("form#wired-form").submit(function(e) {
    e.preventDefault();
    var $form = $(this);

    $.post("/api/wired_applicants", $form.serialize())
      .done(function(resp) {
        $("#wired-form-section").html(resp.template);
      })
      .fail(function(error) {
        alert("Something went wrong. Try again, or email getstarted@zurb.com");
      });
  });
  $(".burger").click(function() {
    if ($(this).hasClass("is-active")) {
      $(".main-nav-center-small").fadeOut();
      $("html").toggleClass("menu-open");
      $(".scroll-navbar").addClass("change");
    } else {
      $(".main-nav-center-small").fadeIn();
      $("html").toggleClass("menu-open");
      $(".scroll-navbar").removeClass("change");
    }
    $(this).toggleClass("is-active");
  });

  scrollToShow();
});
d(function(cowKeys) {
  // DA COWS
  CowLogic.setup();
  konami(cowKeys);
  cowDozer(cowKeys);
  setupSpaceCow(cowKeys);

  $("#the-cow").click(function() {
    $("#beamButton").addClass("shake");
    setTimeout(function() {
      $("#beamButton").removeClass("shake");
    }, 1000);
  });

  $("#holy-cow").click(function() {
    window.COW.unlock("b60c5f4d-a8ef-4b13-b02f-887c14e3efdf", cowKeys);
  });
  //bike cow
  $(
    'img[src="https://zurb-dot-com-prod.s3.amazonaws.com/asset/595/raw_2F87045029-930d-41f9-85d3-891c312f7dd3_2Fcow-design.png"]'
  ).click(function() {
    window.COW.unlock("46a491e1-0a19-46cd-b45f-99cb63409d39", cowKeys);
  });

  // contact form (lead generation)
  $("form#leadForm").submit(function(e) {
    e.preventDefault();
    var $form = $(this);

    var afterLead = function() {
      $(".cow-section").addClass("is-beaming");
      $(".hide-me").addClass("is-opacity-zero");
      setTimeout(function() {
        $(".hide-me").addClass("is-visibility-hidden");
      }, 1000);
      setTimeout(function() {
        window.COW.unlock("7dd823bd-1762-45ce-ba99-b5ec8105855c", cowKeys);
      }, 7000);
    };
    $.post("/api/leads", $form.serialize())
      .done(function(lead) {
        afterLead();
      })
      .fail(function(error) {
        if (error.status == 422) {
          afterLead();
        } else {
          console.log("error!", error);
        }
      });
  });

  $(document).on("turbolinks:load", function() {
    CowLogic.setup();
    setupSpaceCow(cowKeys);
    // contact form (lead generation)
    $("form#leadForm").submit(function(e) {
      e.preventDefault();
      var $form = $(this);

      var afterLead = function() {
        $(".cow-section").addClass("is-beaming");
        $(".hide-me").addClass("is-opacity-zero");
        setTimeout(function() {
          $(".hide-me").addClass("is-visibility-hidden");
        }, 1000);
        setTimeout(function() {
          window.COW.unlock("7dd823bd-1762-45ce-ba99-b5ec8105855c", cowKeys);
        }, 7000);
      };
      $.post("/api/leads", $form.serialize())
        .done(function(lead) {
          afterLead();
        })
        .fail(function(error) {
          if (error.status == 422) {
            afterLead();
          } else {
            console.log("error!", error);
          }
        });
    });

    $("#the-cow").click(function() {
      $("#beamButton").addClass("shake");
      setTimeout(function() {
        $("#beamButton").removeClass("shake");
      }, 1000);
    });

    $("#holy-cow").click(function() {
      window.COW.unlock("b60c5f4d-a8ef-4b13-b02f-887c14e3efdf", cowKeys);
    });

    //bike cow
    $(
      'img[src="https://zurb-dot-com-prod.s3.amazonaws.com/asset/595/raw_2F87045029-930d-41f9-85d3-891c312f7dd3_2Fcow-design.png"]'
    ).click(function() {
      window.COW.unlock("46a491e1-0a19-46cd-b45f-99cb63409d39", cowKeys);
    });
  });
});
