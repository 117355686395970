import _ from 'lodash';

var setupScrollHandling = function() {
  var isPastIntro = false,
      $window = $(window),
      $moonCurve = $(".moon-curve"),
      $scrollNav = $(".scroll-navbar"),
      scrollTimeOut = true,
      lastYPos = 0,
      yPos = 0,
      yPosDelta = 40,
      $subNav = $('.sub-nav-links'),
      navHeight = $scrollNav.outerHeight();

  $window.off('scroll.changehandle').on('scroll.changehandle', function() {
    var scroll = $window.scrollTop();
    scrollTimeOut = true;
    if(scroll > 10) {
      $scrollNav.addClass("change");
    } else {
      $scrollNav.removeClass("change");
    }

    if($moonCurve.length > 0) {
      var objectPosition = $moonCurve.offset().top;
      if (scroll > objectPosition) {
        isPastIntro = true;

      } else {
        isPastIntro = false;
        yPosDelta = 40;
      }
    }
  });

  $window.off('scroll.mainnav').on('scroll.mainnav', _.throttle(function() {
    yPos = $window.scrollTop();

    if(Math.abs(lastYPos - yPos) >= yPosDelta) {
      if (yPos > lastYPos && yPos > navHeight){
        $scrollNav.addClass('hide-nav');
        $subNav.removeClass('pushed');
        yPosDelta = 250;
      } else {
        $scrollNav.removeClass('hide-nav');
        yPosDelta = 40;
        if(isPastIntro) {
          $subNav.addClass('pushed');
          yPosDelta = 40;
        }
      }
      lastYPos = yPos;
    }
  }, 250));

  if ( $( ".sub-nav-scroll" ).length ) {
    var fixmeTop = $('.sub-nav-scroll').offset().top;
    $window.off('scroll.subnav').on('scroll.subnav', function() {
      var currentScroll = $window.scrollTop();
      if (currentScroll >= fixmeTop) {
        $subNav.addClass('fixed');
      } else {
        $subNav.removeClass('fixed').removeClass('pushed');
      }
    });
  }
}

export default setupScrollHandling;
