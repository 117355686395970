export default function(e) {
  if (typeof(ga) === 'function') {
    var url = e.originalEvent.data.url;

    ga('set', 'location', url);
    ga('send', 'pageview');
    ga('corporate.send', 'pageview');

    if(url.match('/blog')) {
      ga('blog.send', 'pageview');
    } else if (url.match(/^((?!blog).)*\/helio/)) {
      ga('helio.send', 'pageview');
    }
  }
}
